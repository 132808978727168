import { Pipe, PipeTransform } from '@angular/core';
import { CustomFieldsType } from '@app/reducers/orm/custom-fields/custom-fields.model';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

@Pipe({
  name: 'customField',
  pure: true,
  standalone: true,
})
export class CustomFieldPipe implements PipeTransform {
  transform(customFieldsType: CustomFieldsType): string {
    switch (customFieldsType) {
      case CustomFieldsType.TEXT:
        return _('Text Field');
      case CustomFieldsType.NUMERIC:
        return _('Numerical Field');
      case CustomFieldsType.DECIMAL:
        return _('Decimal Field');
      case CustomFieldsType.MONEY:
        return _('Monetary Value');
      case CustomFieldsType.DATE:
        return _('Date Picker');
      case CustomFieldsType.BOOLEAN:
        return _('Checkbox');
      case CustomFieldsType.SELECT:
        return _('Single-select Dropdown');
      case CustomFieldsType.MULTI_SELECT:
        return _('Multi-select Dropdown');
      default:
        return _('');
    }
  }
}
